<template>
  <div id="content">
    <Login></Login>
  </div>
</template>

<script>
import Login from '@/components/Login'
export default {
  components: {
    Login
  }
}
</script>

<style lang="scss" scoped>
#content {
  text-align: center;
  margin: auto;
  width: 100%;
}
</style>
