<template>
  <div id="login">
    <FormTop titleTop="登录"> </FormTop>
    <inputMine
      placeholder="用户名 / 电话号码 / Email"
      type="text"
      rule="^.{1,16}$"
      style="margin: 4.157vm 0"
      @inputChange="(res) => (usermodel.username = res)"
      inputName="username"
      errmsg="帐号不能为空"
    >
    </inputMine>

    <inputMine
      placeholder="密码"
      type="password"
      rule="^.{6,16}$"
      style="margin: 4.157vm 0"
      @inputChange="(res) => (usermodel.password = res)"
      inputName="passwd"
      errmsg="密码在6-16位之间"
    >
    </inputMine>
    <inputMine
      placeholder="单击图片换一张"
      type="text"
      rule="^.{1,16}$"
      @captchaChange="captchaChange"
      inputName="captcha"
      ifVerifyOnline
      errmsg="验证码不能为空"
    >
      <img :src="src" height="30px" width="79.999px" @click="getCodeSrc" />
    </inputMine>

    <fomrBtn @submitClick="submitClick"></fomrBtn>

    <LoginBottom
      message="没有账号？立即注册"
      @gotoReg="gotoReg()"
      @toBuddhism="gotoBuddhism()"
    ></LoginBottom>
  </div>
</template>

<script>

import FormTop from './common/FormTop.vue'
import inputMine from './common/inputMine.vue'
import fomrBtn from './common/formBtn.vue'
import LoginBottom from './common/Loginbottom.vue'
export default {
  components: { FormTop, inputMine, fomrBtn, LoginBottom },
  data () {
    return {
      usermodel: {
        username: '',
        password: ''
      },
      captcha: '',
      src: '',
      state: '',
      ifVerifyOnline: true
    }
  },
  beforeMount () {
    if (!localStorage.getItem('configList')) {
      this.$store.dispatch('getConfigsDataAction')
    }
    this.$store.commit('hideShowTabbar', false)
  },
  mounted () {
    this.src = 'https://api.sila.ink/api.php/Login/verify'
  },
  computed: {
    // ...mapState(['isLogin']),
  },
  methods: {
    captchaChange (state) {
      this.state = state
    },
    // <!--提交登录-->
    submitClick () {
      //   this.$store.dispatch('getConfigsDataAction')
      if (this.validateUsername() && this.validateCaptcha() && this.usermodel.password) {
        this.$store.dispatch('loginAction', this.usermodel)
      }
    },
    validateUsername () {
      const rule = '^.{1,16}$'
      const regs = new RegExp(rule)
      console.log(this.usermodel.username, 'usermaodel login')
      if (this.usermodel.username || regs.test(this.usermodel.username)) {
        return true
      } else {
        this.$msg({ message: '用户名不能为空或者不多于16位' })
        return false
      }
    },
    validateCaptcha () {
      //   console.log(this.state)
      if (this.state === true) {
        return true
      } else if (this.state === false) {
        this.$msg({ message: '验证码不正确' })
        return false
      } else {
        this.$msg({ message: 'Wait...' })
        return false
      }
    },
    // <!--进入注册页-->
    gotoReg () {
      this.$router.push({
        path: '/reg'
      })
    },
    gotoBuddhism () {
      this.$store.commit('hideShowTabbar', true)
      this.$router.push({
        path: '/buddhism'
      })
    },
    getCodeSrc () {
      this.src = 'https://api.sila.ink/api.php/Login/verify?' + Math.random()
    }
  },
  beforeUnmount () {
    // this.$store.commit('hideShowTabbar', true)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only 5757450563-->
<style scoped lang="scss">
#login {
  max-width: 94.444vw;
  margin: 16.667vw auto;
  background: #fff;
  padding: 5.556vw 11.111vw;
  border-radius: 2.778vw;
  position: relative;
  z-index: 9;
}
</style>
